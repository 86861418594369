import React from "react";
import cardOne from "../assets/image/card-one.jpg";
import cardTwo from "../assets/image/card-two.jpg";
import cardThree from "../assets/image/card-three.jpg";
import Card from "./Card";

const card_services = [
  {
    title: "Secure Data Storage",
    // titleTwo: "with high encryption",
    disc: "Safeguard the acquisition of critical client data with advanced encryption capabilities, ",
    discShow: "ensuring utmost security and confidentiality.",
    imgUrl: cardOne,
  },
  {
    title: "Mobile Data Hub",
    // titleTwo: "share data on the go",
    disc: "Enhance operational effectiveness through the seamless storage, access, and secure ",
    discShow:   "sharing of data across geographical locations and time constraints.",
    imgUrl: cardTwo,
  },
  {
    title: "Digital Signature",
    titleTwo: "",
    disc: "Leverage our e-signatures to optimize efficiency, reduce expenses, bolster securtity",
    discShow:
      "and enhance adaptability facilitating seamless document signing, tracking and management regardless of time or location.",
    imgUrl: cardThree,
  },
];
const HomeCard = () => {
  return (
    <div
      id="Threecard"
      className="three-card"
      style={{ width: "100%", overflow: "hidden" }}
    >
      <div className="row">
        {card_services.map((item, index) => {
          return (
            <Card
              key={index}
              title={item.title}
              titleTwo={item.titleTwo}
              disc={item.disc}
              discShow={item.discShow}
              imgUrl={item.imgUrl}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HomeCard;
