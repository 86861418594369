import React, { useEffect, useState } from "react";
import checkIcon from "../assets/image/right-icon.png";
import SimpleCard from "./SimpleCard";
import SwitchButton from "./SwitchButton";
import { getPlans } from "../service/service";
import { Button, Chip, Switch } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import bestSellerImage from "../assets/image/pngwing.com.png";

export const planDescription = [
  "Customized plans designed to fit your business requirements.",
  "The preferred substruction plan.",
  "Make your business accessible 24/7.",
];

const SimplePricing = () => {
  // Define isSwitchOn and setIsSwitchOn using useState
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMonthly, setIsMonthly] = useState(true);

  useEffect(() => {
    const fetchPlans = async () => {
      setIsLoading(true);
      try {
        const res = await getPlans();
        setPlans(res?.data?.data);
        setIsLoading(false);
        return res;
      } catch (err) {
        setIsLoading(false);
        throw new Error(`Error fetching data: ${err.message}`);
      }
    };
    fetchPlans();
  }, []);

  // Separate the objects based on the value of the recurring property
  const separatedData = plans?.reduce((acc, obj) => {
    const recurringValue = obj?.recurring; // Assuming product_prices always has at least one item

    // Check if the recurring value exists as a key in the accumulator
    if (!acc[recurringValue]) {
      // If not, initialize an array with the current object
      acc[recurringValue] = [obj];
    } else {
      // If yes, push the current object to the existing array
      acc[recurringValue].push(obj);
    }

    return acc;
  }, {});

  const recurring12Array = separatedData["12"] || [];
  const recurring1Array = separatedData["1"] || [];

  const premiumIndex = recurring12Array?.sort((a, b) => {
    const order = { "Essential": 0, "Premium": 1 };
    return (order[a.package.title] ?? 2) - (order[b.package.title] ?? 2);
  });
  
  
  
  const premiumIndex2 = recurring1Array?.sort((a, b) => {
    const order = { "Essential": 0, "Premium": 1 };
    return (order[a.package.title] ?? 2) - (order[b.package.title] ?? 2);
  });

  const handleSwitchToggle = () => {
    setIsMonthly((prevState) => !prevState);
    console.log("isMonthly:", isMonthly);
  };

  return (
    // <div id="simplepricing" className="simple-pricing">
    //   <div className="container">
    //     <div className="sec-head">
    //       <h3 className="roco-font mb-3">BEST & SIMPLE PRICING</h3>
    //       <p style={{ lineHeight: '33px' }}>Lorem Ipsum is simply dummy text of the printing and typese ttingindus orem <br /> Ipsum has beenthe standard dummy.</p>
    //     </div>
    //     <SwitchButton isSwitchOn={isSwitchOn} setIsSwitchOn={setIsSwitchOn} />
    //     <div className="row">
    //       <SimpleCard
    //         title={isSwitchOn ? "Monthly" : "YEARLY"}
    //         pera="Porem ipsum dolor sit amet, consectetur adipiscing elit."
    //         start="Starts at"
    //         amt={isSwitchOn ? "" : "$250"}
    //         off={isSwitchOn ? "" : "62% off"}
    //         amount={isSwitchOn ? "$90.99" : "$95.99"}
    //         secondpera="/Porem ipsum dolor sit amet, consectetur adipiscing elit."
    //         imgUrl={checkIcon}
    //         threepera="threepera"
    //         fourpera="fourpera"
    //       />
    //       <SimpleCard
    //         title={isSwitchOn ? "Monthly" : "YEARLY"}
    //         pera="Porem ipsum dolor sit amet, consectetur adipiscing elit."
    //         start="Starts at"
    //         amt={isSwitchOn ? "" : "$250"}
    //         off={isSwitchOn ? "" : "62% off"}
    //         amount={isSwitchOn ? "$90.99" : "$95.99"}
    //         secondpera="/Porem ipsum dolor sit amet, consectetur adipiscing elit."
    //         imgUrl={checkIcon}
    //         threepera="threepera"
    //         fourpera="fourpera"
    //       />
    //       <SimpleCard
    //         title={isSwitchOn ? "Monthly" : "YEARLY"}
    //         pera="Porem ipsum dolor sit amet, consectetur adipiscing elit."
    //         start="Starts at"
    //         amt={isSwitchOn ? "" : "$250"}
    //         off={isSwitchOn ? "" : "62% off"}
    //         amount={isSwitchOn ? "$90.99" : "$95.99"}
    //         secondpera="/Porem ipsum dolor sit amet, consectetur adipiscing elit."
    //         imgUrl={checkIcon}
    //         threepera="threepera"
    //         fourpera="fourpera"
    //       />
    //     </div>
    //   </div>
    // </div>
    <div id="simplepricing">
      <div className="d-flex align-items-center  flex-column p-4">
        <h1
          style={{ width: "70%", fontSize: "32px" }}
          className=" text-center "
        >
          Find Your Perfect Fit <br />
        </h1>
        <p style={{ width: "35%" }} className=" text-center cad-text-small">
          Start Your Free 14-Day Trial (Cancel Anytime)
        </p>
        <span className="mb-2 cad-fw-500 cad-text-medium">
          <span
            style={{
              color: isMonthly ? "#4CCD8D" : "",
            }}
          >
            {" "}
            Monthly{" "}
          </span>{" "}
          <Switch
            style={{
              color: "#4CCD8D",
            }}
            value={true}
            onChange={handleSwitchToggle}
          />{" "}
          <span
            style={{
              color: isMonthly ? "#4CCD8D" : "",
            }}
          >
            {" "}
            Anually{" "}
          </span>
        </span>
      </div>
      <div
        className={`w-100 d-flex justify-content-center align-items-center gap-5 flex-wrap ${isLoading ? "h-50" : ""
          }`}
      >
        {isLoading
          ? // <Loader />
          "loading..."
          : isMonthly
            ? premiumIndex2?.map((plan, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: 280,
                    height: 345,
                    border: plan?.package.title === "Premium" ? "1px solid #0D6EFD" : "1px solid #4ccd8d",
                    transition: "transform 0.3s ease",
                    position: "relative",

                    // backgroundColor: themeColorsObject.premiumCard,
                  }}
                  className="subscription_card  rounded-3 p-3"
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  {/* {plan?.package.title === "Premium" ?<img style={{
                    width: 55,
                    position:"absolute",
                    top:-7.5,
                    right:6
                  }} src={bestSellerImage} />:""} */}

                  {plan?.package.title === "Premium" ? <span
                    style={{
                      fontSize: "13px",
                      backgroundColor: "#bed6f8",
                      color: "#4CCD8D",
                      position: "absolute",
                      top: -10.5,
                      left: 42,
                      padding: 1.5
                    }}
                    className="ps-5 fw-bold pe-5  text-primary  rounded-5 "
                  >
                    MOST POPULAR
                  </span> : ""}
                  <span
                    style={{ borderBottom: "1px dashed #4CCD8D" }}
                    className="d-flex flex-column justify-content-start pb-3"
                  >
                    <span className="cad-fw-600 cad-text-medium">
                      {plan?.package.title}
                    </span>
                    <span
                      style={{ height: "30px" }}
                      className="cad-fw-500 cad-text-small"
                    >
                      {planDescription[index]}
                    </span>
                  </span>

                  <span className="d-flex flex-column justify-content-start pb-3">
                    <h5 className="mt-2 cad-fw-600">
                      {plan?.currency} {plan?.unit_amount}{" "}
                      {plan?.recurring === "1" ? "Monthly" : "Anually"}
                    </h5>
                    <HashLink to="/Pricing#pricing-container">
                      <Button
                        // onClick={() => handleMakePayment(plan?.product_prices[0]?.id)}
                        style={{
                          backgroundColor: plan?.package.title === "Premium" ? "#0D6EFD" : "#4CCD8D",
                          textTransform: "none",
                          width: "100%",
                        }}
                        className="btn cad-text-small text-white mt-2"
                      >
                        Get Started
                      </Button>
                    </HashLink>
                    <div className="d-flex flex-column gap-2 mt-3">
                      <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" />{" "}
                        {plan?.package?.storage} GB Secure Data Storage
                      </span>
                      <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" /> Up to{" "}
                        {plan?.package?.users} accounts
                      </span>
                      <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" />{" "}
                        {plan?.package?.domain
                          ? "Brand customization"
                          : "Detailed                     analytics"}
                      </span>
                      <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" /> Seamless
                        integration with apps
                      </span>
                      {/* <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" /> 24/7
                        assistance
                      </span> */}
                      <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" /> Unlimited
                        website bandwidth
                      </span>
                    </div>
                  </span>
                </div>
              );
            })
            : premiumIndex?.map((plan, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: 280,
                    height: 345,
                    border: plan?.package.title === "Premium" ? "1px solid #0D6EFD" : "1px solid #4ccd8d",
                    transition: "transform 0.3s ease",
                    position: "relative"
                    // backgroundColor: themeColorsObject.premiumCard,
                  }}
                  className="subscription_card  rounded-3 p-3"
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "scale(1.1)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >

                  {plan?.package.title === "Premium" ? <span
                    style={{
                      fontSize: "13px",
                      backgroundColor: "#bed6f8",
                      color: "#4CCD8D",
                      position: "absolute",
                      top: -10.5,
                      left: 42,
                      padding: 1.5
                    }}
                    className="ps-5 fw-bold pe-5  text-primary  rounded-5 "
                  >
                    MOST POPULAR
                  </span> : ""}

                  <span
                    style={{ borderBottom: "1px dashed #4CCD8D" }}
                    className="d-flex flex-column justify-content-start pb-3"
                  >
                    <span className="cad-fw-600 cad-text-medium">
                      {plan?.package.title}
                    </span>
                    <span
                      style={{ height: "30px" }}
                      className="cad-fw-500 cad-text-small"
                    >
                      {planDescription[index]}
                    </span>
                  </span>

                  <span className="d-flex flex-column justify-content-start pb-3">
                    <h5 className="mt-2 cad-fw-600">
                      {plan?.currency} {plan?.unit_amount}{" "}
                      {plan?.recurring === "1" ? "Monthly" : "Anually"}
                      <Chip
                        size="small"
                        variant="outlined"
                        color="primary"
                        style={{
                          color: plan?.package.title === "Premium" ? "#0D6EFD" : "#4CCD8D",

                          borderColor: plan?.package.title === "Premium" ? "#0D6EFD" : "#4CCD8D",
                        }}
                        className="ms-2  cad-text-small"
                        label={`Save ${plan?.discount}%`}
                      />
                    </h5>

                    <HashLink to="/Pricing#pricing-container">
                      <Button
                        // onClick={() => handleMakePayment(plan?.id)}
                        style={{
                          backgroundColor: plan?.package.title === "Premium" ? "#0D6EFD" : "#4CCD8D",
                          textTransform: "none",
                          width: "100%",
                        }}
                        className="btn cad-text-small text-white mt-2"
                      >
                        Get Started
                      </Button>
                    </HashLink>
                    <div className="d-flex flex-column gap-2 mt-3">
                      <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" />{" "}
                        {plan?.package?.storage} GB Storage
                      </span>
                      <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" /> Up to 25
                        accounts
                      </span>
                      <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" /> Detailed
                        analytics
                      </span>
                      <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" /> Seamless
                        integration with apps
                      </span>
                      {/* <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" /> 24/7
                        assistance
                      </span> */}
                      <span className="cad-text-small cad-fw-600">
                        <CheckIcon className="cad-text-medium" /> Unlimited
                        website bandwidth
                      </span>
                    </div>
                  </span>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default SimplePricing;
