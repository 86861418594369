import React, { useEffect, useState } from "react";
import crossicon from "../assets/image/53443.png";
import checkicon from "../assets/image/53444.png";
import SwitchButton from "./SwitchButton";
import PrimaryBtn from "./PrimaryBtn";
import { HashLink } from "react-router-hash-link";
import { getPlans } from "../service/service";
import { Chip } from "@mui/material";
import { planDescription } from "./SimplePricing";

const PricingTable = () => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  
  const [mappedData, setMappedData] = useState();
  // const fetchPlans = async () => {
  //   setIsLoading(true);
  //   try {
  //     const res = await getPlans();
  //     setPlans(res?.data?.data);
  //     setIsLoading(false);
  //     return res;
  //   } catch (err) {
  //     setIsLoading(false);
  //     throw new Error(`Error fetching data: ${err.message}`);
  //   }
  // };
  useEffect(() => {
    let recurring12Array, recurring1Array;
    (async () => {
      try {
        const res = await getPlans();
        setIsLoading(false);
        const separatedData = res?.data?.data?.reduce((acc, obj) => {
          const recurringValue = obj?.recurring; // Assuming product_prices always has at least one item

          // Check if the recurring value exists as a key in the accumulator
          if (!acc[recurringValue]) {
            // If not, initialize an array with the current object
            acc[recurringValue] = [obj];
          } else {
            // If yes, push the current object to the existing array
            acc[recurringValue].push(obj);
          }

          return acc;
        }, {});
        recurring12Array = separatedData["12"] || [];
        recurring1Array = separatedData["1"] || [];
        const premiumIndex = recurring12Array?.sort((a, b) => {
          const order = { "Essential": 0, "Premium": 1 };
          return (order[a.package.title] ?? 2) - (order[b.package.title] ?? 2);
        });
        
        
        
        const premiumIndex2 = recurring1Array?.sort((a, b) => {
          const order = { "Essential": 0, "Premium": 1 };
          return (order[a.package.title] ?? 2) - (order[b.package.title] ?? 2);
        });
        setMappedData(isSwitchOn ? premiumIndex2 : premiumIndex);
      } catch (err) {
        setIsLoading(false);
        throw new Error(`Error fetching data: ${err.message}`);
      }
    })();
  }, [isSwitchOn]);

// 
  return (
    <div className="pricing-table" style={{ padding: "0 15px 5rem 15px" }}>
      <div className="container-fluid">
        <SwitchButton isSwitchOn={isSwitchOn} setIsSwitchOn={setIsSwitchOn} />
        <table>
          <tr>
            <th
              className="top-fixed w-250px"
              style={{ padding: "24px", border: "none" }}
            >
              <div className="plan">
                <h3>
                  Not Sure Which
                  <br /> Plan Is For You?
                </h3>
                <p>
                  If you're unsure which plan best suits your needs, don't
                  hesitate to get in touch with us for personalized assistance
                  and guidance.
                </p>
                <div className="d-flex">
                  <HashLink
                    style={{
                      color: "#4CCD8D",
                      border: "1px solid",
                      backgroundColor: "transparent",
                      borderRadius: "5px",
                      padding: "15px 30px",
                      margin: "auto",
                      textAlign: "center",
                      fontSize: "18px",
                      textDecoration: "none",
                    }}
                    to={"/#Contactus"}
                  >
                    Contact Us
                  </HashLink>
                </div>
              </div>
            </th>
            {mappedData?.map(
              (
                { package: pakageData, discount, currency, unit_amount,  id },
                index
              ) => (
                <th className="top-fixed w-250px">
                  <div className="yearly">
                    <h4
                      style={{
                        fontWeight: "600",
                        lineHeight: "40px",
                        color: "#2E2E2E",
                      }}
                    >
                      {pakageData?.title}
                    </h4>
                    <p style={{ fontSize: "14px", color: "#696868" }}>
                      {planDescription[index]}
                    </p>
                    <div className="start">
                      <p style={{ color: "#2E2E2E", marginBottom: "6px" }}>
                        Starts at
                      </p>
                      <div className="dis">
                        {discount === "0" ? (
                          ""
                        ) : (
                          <Chip
                            size="small"
                            variant="outlined"
                            color="primary"
                            className="cad-text-small"
                            label={`Save ${discount}%`}
                          />
                        )}
                      </div>
                      <div className="amount mt-2">
                        <h4
                          style={{ fontWeight: "600" }}
                        >{`${currency} ${unit_amount}`}</h4>
                      </div>
                      <div className="start-btn">
                        <PrimaryBtn
                          path={`${process.env.REACT_APP_AGENT_BASEURL}/registration-plan/${id}`}
                          title="Buy Now"
                        />
                      </div>
                    </div>
                  </div>
                </th>
              )
            )}
          </tr>
          <tr>
            <td style={{ padding: "24px", border: "none" }}></td>
            <td style={{ padding: "24px" }}></td>
            <td style={{ padding: "24px" }}></td>
            <td style={{ padding: "24px" }}></td>
          </tr>

          <tr>
            <td>
              <span>Number maximum users per Org</span>
            </td>

            {mappedData?.map(({ package: pakageData }, index) => (
              <td style={{ textAlign: "center" }}>{pakageData?.users}</td>
            ))}
          </tr>
          <tr>
            <td>
              <span>Secure Data Storage</span>
            </td>
            {mappedData?.map(({ package: pakageData }, index) => (
              <td style={{ textAlign: "center" }}>{pakageData?.storage} GB</td>
            ))}
          </tr>
          <tr>
            <td>
              <span>Brand Customization</span>
            </td>
            {mappedData?.map(({ package: pakageData }, index) => (
              <td style={{ textAlign: "center" }}>
                {pakageData?.domain ? (
                  <img alt="" src={checkicon} />
                ) : (
                  <img alt="" src={crossicon} />
                )}
              </td>
            ))}
          </tr>
          <tr>
            <td>
              <span>Multi region data storage</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={crossicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Client onboarding/intake</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Secure portal for clients</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Secure Messaging</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>e-Signatures</span>
            </td>
            <td style={{ textAlign: "center" }}>$</td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />*
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Update once, update everywhere</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />*
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Create templated forms</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Task based actions</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Contact Management</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Guest forms</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={crossicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Legal trail</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
        </table>
        <div className="table-sec">Encryption & Security</div>
        <table>
          <tr>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
          </tr>
          <tr>
            <td>
              <span>Encrypted storage</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Encryption at rest and in transit</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Zero-knowledge authentication</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Two-step verification</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
        </table>
        <div className="table-sec">Data Management</div>
        <table>
          <tr>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
          </tr>
          <tr>
            <td>
              <span>Granular permission level for collaborators</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Revoke document access</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          <tr>
            <td>
              <span>Full Text Document Search</span>
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
        </table>
        <div className="table-sec">Encryption & Security</div>
        <table>
          <tr>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
            <th
              className="w-250px"
              style={{ border: "none", padding: "0" }}
            ></th>
          </tr>
          {/* <tr>
            <td>
              <span>Support center</span>
            </td>
            <td style={{ textAlign: "center" }}>$</td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
              (8/5)
            </td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
              (24/7)
            </td>
          </tr> */}
          <tr>
            <td>
              <span>Deployment support</span>
            </td>
            <td style={{ textAlign: "center" }}>$</td>
            <td style={{ textAlign: "center" }}>$</td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
          {/* <tr>
            <td>
              <span>Training</span>
            </td>
            <td style={{ textAlign: "center" }}>$</td>
            <td style={{ textAlign: "center" }}>Virtual</td>
            <td style={{ textAlign: "center" }}>Virtual or On-site</td>
          </tr> */}
          <tr>
            <td>
              <span>Existing client data migration</span>
            </td>
            <td style={{ textAlign: "center" }}>$</td>
            <td style={{ textAlign: "center" }}>$</td>
            <td style={{ textAlign: "center" }}>
              <img alt="" src={checkicon} />
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default PricingTable;
