import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../src/pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Faq from "./pages/Faq";
import TermCondition from "./pages/TermCondition";
import GdprPolicy from "./pages/GdprPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import About from "./pages/About";
import Registration from "./pages/Registration";
import RegistrationTwo from "./pages/RegistrationTwo";
import Stapper from "./pages/Stapper";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import Mail from "./pages/Mail";
import ResetPassword from "./pages/ResetPassword";
import Pricing from "./pages/Pricing";
import SignIn from "./pages/SignIn";
import ScrollToTop from "./components/ScrollTop";

// Create a layout component that includes Navbar and Footer
function Layout({ children }) {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/faq" element={<Layout><Faq /></Layout>} />
        <Route path="/termcondition" element={<Layout><TermCondition /></Layout>} />
        <Route path="/gdprPolicy" element={<Layout><GdprPolicy /></Layout>} />
        <Route path="/privacypolicy" element={<Layout><PrivacyPolicy /></Layout>} />
        <Route path="/about" element={<Layout><About /></Layout>} />
        <Route path="/pricing" element={<Layout><Pricing /></Layout>} />
        {/* Route without Navbar and Footer */}
        <Route path="/registration" element={<Registration />} />
        <Route path="/registrationtwo" element={<RegistrationTwo />} />
        <Route path="/stapper" element={<Stapper />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/mail" element={<Mail />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/signin" element={<SignIn />} />
      </Routes>
    </>
  );
}

export default App;
