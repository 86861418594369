import React, { useEffect, useState } from 'react'
import InnerBanner from '../components/InnerBanner'
import { getTermsData } from '../service/service';

const TermCondition = () => {

    const [termsData, setTermsData] = useState()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getTermsData(setTermsData); // Call the function and pass the setter function
                console.log(data);
            } catch (error) {
                console.error('Error fetching home card data:', error);
            }
        };
        fetchData(); // Call the function when the component mounts
    }, []);

    return (
        <>
            <InnerBanner title="Terms & Condition" />
            <div className="condition">
                {/* <div className="container" >
                    <div className="inner-head">
                        <h2>Terms & Condition</h2>
                    </div>
                    <div className="cards-head">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. Quisque sagittis </p>
                    </div>
                    <div className="cards">
                        <h3>Acceptance of Terms</h3>
                        <p>By accessing or using the Website, you agree to be bound by these Terms and all applicable laws and regulations.
                            If you do not agree with any of these Terms, you are prohibited from using the Website.</p>
                    </div>
                    <div className="cards">
                        <h3>License</h3>
                        <p>Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access
                            and use the Website for its intended purpose. This license does not permit you to modify, distribute, reproduce,
                            or exploit any part of the Website without our prior written consent.</p>
                    </div>
                    <div className="cards">
                        <h3>User Obligations</h3>
                        <ul>
                            <p>When using the Website, you agree to:</p>
                            <p>a. Provide accurate and complete information during the registration process.</p>
                            <p>b. Maintain the confidentiality of your account information.</p>
                            <p>c. Accept responsibility for all activities that occur under your account.</p>
                            <p>d. Comply with all applicable laws and regulations.</p>
                        </ul>
                    </div>
                    <div className="cards">
                        <h3>Intellectual Property</h3>
                        <p>The Website and its original content, features, and functionality are owned by us and are protected by
                            international copyright, trademark, patent, trade secret, and other intellectual property laws.</p>
                    </div>
                    <div className="cards">
                        <h3>Limitation of Liability</h3>
                        <p>In no event shall we be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of
                            or relating to your use of the Website, including, but not limited to, any errors or omissions in the content,
                            loss or corruption of data, or any other loss or damage.</p>
                    </div>
                    <div className="cards">
                        <h3>Third-Party Links</h3>
                        <p>The Website may contain links to third-party websites or services that are not owned or controlled by us. We have
                            no control over and assume no responsibility for the content, privacy policies, or practices of any third-party
                            websites or services. You acknowledge and agree that we shall not be responsible or liable for any damages or
                            loss caused by the use of such third-party websites or services.</p>
                    </div>
                    <div className="cards">
                        <h3>Modification and Termination</h3>
                        <p>We reserve the right to modify or terminate the Website, its features, or these Terms at any time, without prior
                            notice. Your continued use of the Website after any modifications to these Terms constitutes your acceptance of
                            the modified Terms.</p>
                    </div>
                    <div className="cards">
                        <h3>Governing Law</h3>
                        <p>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to
                            its conflict of laws principles.</p>
                    </div>
                </div> */}


                <div className="container">
                    {termsData && termsData?.map((htmlContent, index) => (
                        <div key={index} dangerouslySetInnerHTML={{ __html: htmlContent?.content }}></div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default TermCondition
