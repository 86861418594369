import React from 'react';
import aboutCard from '../assets/image/53498.png';
import check from '../assets/image/53308.png';
import DoneIcon from '@mui/icons-material/Done';

const About = ({ data }) => {
    return (
        <div id='Aboutus' className="about-us">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="about-image" style={{ position: 'relative' }}>
                            <div className="avatar">
                                <div className="top-card" style={{ position: 'absolute' }}>
                                    <div className="prod" style={{ backgroundColor: '#fff', boxShadow: '0px 1px 5px #797979', borderRadius: '5px', padding: '8px 11px' }}>
                                        <p>Optimize Flow</p>
                                        <div><img src={check} />Productivity</div>
                                    </div>
                                </div>
                            </div>
                            <img className="main-img-card" src={aboutCard} />
                            <div className="avatar">
                                <div className="btm-card">
                                    <div className="smail" style={{ position: 'relative', backgroundColor: '#fff', boxShadow: '0px 1px 5px #797979', borderRadius: '5px', padding: '8px 11px' }}><p>Get Max Output</p></div>
                                    <div className="prod" style={{ backgroundColor: '#fff', boxShadow: '0px 1px 5px #797979', borderRadius: '5px', padding: '8px 11px' }}>
                                        <p>Emphasise ease of use</p>
                                        <div><img src={check} />Productivity</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-content">
                            <h2>ABOUT US</h2>
                            <h4 className="mb-4 mt-3" style={{ fontWeight: 400, lineHeight: '34px', fontSize: 22 }}>
                                Cloudifydocs is application based on cloud native solutions enabling businesses with a secure, simple and efficient client management system. It provides:
                            </h4>
                            <div className="d-flex">
                                <DoneIcon style={{ marginRight: 10, color: '#4ccd8d', fontSize: '30px' }} />
                                <p>An automated platform to gather critical and confidential data electronically </p>
                            </div>
                            <div className="d-flex">
                                <DoneIcon style={{ marginRight: 10, color: '#4ccd8d', fontSize: '30px' }} />
                                <p>An ultra-secure place in the cloud to store and manage data </p>
                            </div>
                            <div className="d-flex">
                                <DoneIcon style={{ marginRight: 10, color: '#4ccd8d', fontSize: '30px' }} />
                                <p>Sync and encrypted file sharing within your organization and clients externally</p>
                            </div>
                            <div className="d-flex">
                                <DoneIcon style={{ marginRight: 10, color: '#4ccd8d', fontSize: '30px' }} />
                                <p>Instant chat messaging with audit trails</p>
                            </div>
                            <div className="d-flex">
                                <DoneIcon style={{ marginRight: 10, color: '#4ccd8d', fontSize: '30px' }} />
                                <p>E-signature functionality </p>
                            </div>
                            <div className="d-flex">
                                <DoneIcon style={{ marginRight: 10, color: '#4ccd8d', fontSize: '30px' }} />
                                <p>Infinite archival of client data</p>
                            </div>

                        </div>
                        {/* <div
                            className="about-content"
                            dangerouslySetInnerHTML={{ __html: data[0]?.about }}
                        > 
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
