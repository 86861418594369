import React, { useState } from 'react'

const StepTwo = ({value, setStep2}) => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (index, val) => {
    setActiveButton(index);
    setStep2(val)
  };

  console.log(activeButton)

  return (
    <div style={{position:'relative'}}>
      <div className="next-step">
            <p>Step 2 Of 3</p> 
        </div>
        <h3>What's Your Primary Role?</h3>
        <div className="select-btn">
            <button className={activeButton === 0 ? 'active' : ''}
              onClick={() => handleButtonClick(0,'Finance')}>
                Finance
            </button>
            <button className={activeButton === 1 ? 'active' : ''}
              onClick={() => handleButtonClick(1,'Director')}>
                Director
            </button>
            <button className={activeButton === 2 ? 'active' : ''}
              onClick={() => handleButtonClick(2,'HR')}>
                HR
            </button>
            <button className={activeButton === 3 ? 'active' : ''}
              onClick={() => handleButtonClick(3,'IT')}>
                IT
            </button>
        </div>
    </div>
  )
}

export default StepTwo
