import React from 'react'
import SecondaryBth from '../components/SecondaryBth'
import { Link } from 'react-router-dom'

const ResetPassword = ({titleTwo}) => {
  return (
    <div className="registration">
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='left-sec'>
                        <div className='logo'>
                            <h2><Link to="/"><strong style={{color:'#4CCD8D'}}>CAD</strong> <span style={{color:'#548CFF'}}>IT Solutions</span></Link></h2> 
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="rigester-box login">
                        <div className="get-start-login mb-4">
                            <h2>Change password</h2>
                        </div>
                        <form>
                            <div className="form-group">
                                <label>Password</label><br/>
                                <input type="text" placeholder="*******" />
                            </div>
                            <div className="form-group mb-0">
                                <label>Confirm Password</label><br/>
                                <input type="text" placeholder="*******" />
                            </div>
                            <div className='sec-btns d-flex justify-content-center mt-5'>
                                <SecondaryBth path='/stapper' titleTwo="Reset Password"/>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ResetPassword
