import React from 'react'
import GoogleIcon from '../assets/image/google-Icons.png'
import Microsoft from '../assets/image/microsoft.png'
import { Link } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox';

const Registration = () => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <div className="registration">
        <div className="container">
            <div className='row'>
                <div className='col-md-6'>
                    <div className='left-sec'>
                        <div className='logo'>
                            <h2><Link to="/"><strong style={{color:'#4CCD8D'}}>CAD</strong> <span style={{color:'#548CFF'}}>IT Solutions</span></Link></h2> 
                        </div>
                        <div className="get-start">
                            <h2>Get Started</h2>
                            <h3>"Welcome to <span>'CAD IT Solutions”</span></h3>
                            <p>Get started, it is free for 14 days. No credit card required.</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="rigester-box">
                        <div className="input-box">
                            <div>
                                <a href="#">
                                <img src={GoogleIcon}/>
                                    sign up with google
                                </a>
                            </div>
                            <div>
                                <a href="#">
                                    <img src={Microsoft}/>
                                    sign up with microsoft
                                </a>
                            </div>
                        </div>
                        <div className="next">
                            <hr></hr>
                            <h2>OR</h2>
                        </div>
                        <div className="sign-up">
                            <input type="text" placeholder="name@gmail.com" />
                            <Link to="/registrationtwo">Sign Up</Link>
                        </div>
                        <div className="check">
                            <Checkbox
                                {...label}
                                sx={{
                                    color:'#4CCD8D',
                                    '&.Mui-checked': {
                                    color: '#4CCD8D',
                                    },
                                }}
                            />  
                            <p>I accept the <Link to="/privacypolicy" style={{borderBottom:'1px solid #0000004f'}}>Privacy Policy</Link> and <Link to="/termcondition" style={{borderBottom:'1px solid #0000004f'}}>Terms & Conditions</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Registration
