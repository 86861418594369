import React from 'react'
import { Link } from 'react-router-dom'
import GoogleIcon from '../assets/image/google-Icons.png'
import Microsoft from '../assets/image/microsoft.png'
import SecondaryBth from '../components/SecondaryBth'
import Checkbox from '@mui/material/Checkbox';


const Login = () => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <div className="registration">
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='left-sec'>
                        <div className='logo'>
                            <h2>
                                <Link to="/"><strong style={{color:'#4CCD8D'}}>CAD</strong> <span style={{color:'#548CFF'}}>IT Solutions</span></Link>
                            </h2> 
                        </div>
                        <div className="get-start">
                            <h2>Log in</h2>
                            <p>Don’t Have An Account? <Link to="/registration" style={{borderBottom:'1px solid #00000057'}}> Create An Account</Link></p>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="rigester-box login">
                        
                        <form>
                            <div className="form-group">
                                <label>Email Address</label><br/>
                                <input type="text" placeholder="reethik@gmail.com" />
                            </div>
                            <div className="form-group mb-0">
                                <label>Password</label><br/>
                                <input type="text" placeholder="*******" />
                            </div>
                            <div className='forget mt-2'>
                                <Link to="/ForgetPassword" style={{borderBottom:'1px solid '}}>Forgot Password</Link>
                            </div>
                            <div className="check">
                                <Checkbox
                                    {...label}
                                    sx={{
                                        color:'#4CCD8D',
                                        '&.Mui-checked': {
                                        color: '#4CCD8D',
                                        },
                                    }}
                                />
                                <p>keep me logged in</p>
                            </div>
                            <div className='sec-btns d-flex justify-content-center mt-5 mb-4'>
                                <SecondaryBth titleTwo="Log In"/>
                            </div>
                        </form>
                        <div className="input-box">
                            <div>
                                <a href="#">
                                    <img src={GoogleIcon} />
                                    Sign Up With Google
                                </a>
                            </div>
                            <div>
                                <Link href="#" className='mb-0'>
                                    <img src={Microsoft} />
                                    Sign Up With Microsoft
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login
