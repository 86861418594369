import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

const PositionedSnackbar = ({ openError, setOpenError }) => {
  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });;
  const { vertical, horizontal, open } = state;

  useEffect(() => {
    if (openError) {
      // Open the Snackbar
      setState({ ...state, open: true });

      // Use setTimeout to close the Snackbar after 3 seconds (3000 milliseconds)
      const timer = setTimeout(() => {
        // handleClose();
        setState({ ...state, open: false });
        setOpenError(false)
      }, 3000);

      // Cleanup the timer when the component unmounts
      return () => clearTimeout(timer);
    }
  }, [openError]); // Add openError as a dependency

 


  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        message="Please select a particular category"
        key={vertical + horizontal}
      />
    </Box>
  );
};

export default PositionedSnackbar;
