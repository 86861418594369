import React from 'react';
import stepOne from '../assets/image/53490.png';
import stepTwo from '../assets/image/53491.png';
import stepthree from '../assets/image/53492.png';
import arrowTop from '../assets/image/53302.png';
import arrowBtm from '../assets/image/53304.png';

const Threestep = () => {
    return (
        <div id='HowItWorks' className="how-to-work">
            <div className="container">
                <div className="sec-head">
                    <h3 className="roco-font mb-3" style={{ color: '#2E2E2E' }}>Get Started In 3 Simple Steps</h3>
                    <p style={{ lineHeight: '33px' }}>Assign, Track, Manage.</p>
                </div>
                <div className="row">
                    <div className="col-md-6 three-step">
                        <div className="stap">
                            <div className="num mb-2" style={{ color: '#AAAAAA', fontWeight: '500' }}>
                                01
                            </div>
                            <h3 className='mb-4' style={{ color: '#2E2E2E', fontWeight: '600' }}>Effortless Setup</h3>
                            <p style={{ lineHeight: '33px' }}>Input client credentials, then proceed with authentication via the client portal for completion.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="images text-center">
                            <img src={stepOne} />
                        </div>
                    </div>
                </div>
                <div className="step-arrow">
                    <img src={arrowTop} />
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="images text-center">
                            <img src={stepTwo} />
                        </div>
                    </div>
                    <div className="col-md-6 three-step">
                        <div className="stap">
                            <div className="num mb-2" style={{ color: '#AAAAAA', fontWeight: '500' }}>
                                02
                            </div>
                            <h3 className='mb-4' style={{ color: '#2E2E2E', fontWeight: '600' }}>Boost Collaboration</h3>
                            <p style={{ lineHeight: '33px' }}>Generate or allocate standard or customized forms as tasks for clients to gather data.</p>
                        </div>
                    </div>
                </div>
                <div className="step-arrow">
                    <img src={arrowBtm} />
                </div>
                <div className="row">
                    <div className="col-md-6 three-step">
                        <div className="stap">
                            <div className="num mb-2" style={{ color: '#AAAAAA', fontWeight: '500' }}>
                                03
                            </div>
                            <h3 className='mb-4' style={{ color: '#2E2E2E', fontWeight: '600' }}>Track Progress In Real-Time </h3>
                            <p style={{ lineHeight: '33px' }}>Never wonder about task status again. See progress updates as tasks are completed.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="images text-center">
                            <img src={stepthree} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Threestep
