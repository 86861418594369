import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

const Footer = () => {
    return (
        <div className="main-footer">
            <div className="container">
                <div className="row">
                    <div className="footer-logo">
                        <Link to='/' style={{ textDecoration: 'none' }}>
                            <h2 style={{ color: '#548CFF', fontWeight: '400', paddingLeft: '14px', marginBottom: '26px' }}>
                                <strong style={{ color: '#4CCD8D' }}>Cloudify</strong><span style={{ fontWeight: '500' }}>Docs</span>
                            </h2>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="icons">
                                    {/* <p style={{ fontWeight: '500' }}>Join our social community</p> */}
                                    {/* <ul className="social-icon">
                                        <li><Link to="#"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                        <li><Link to="#"><i className="fa fa-youtube-play" aria-hidden="true"></i></Link></li>
                                        <li><Link to="#"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
                                        <li><Link to="#"><i className="fa fa-instagram" aria-hidden="true"></i></Link></li>
                                        <li><Link to="#"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 pl-0">
                                <div className="footer-page">
                                    <ul>
                                        <li><HashLink to="/termcondition">Terms & Condition</HashLink></li>
                                        <li><HashLink to="/privacypolicy/#banner-top">Privacy Policy</HashLink></li>
                                        {/* <li><HashLink to="/gdprPolicy">GDPR policy</HashLink></li> */}
                                        <li><HashLink to="/faq">FAQ'S</HashLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col md-5"></div>

                </div>
                <div className="copywrite">
                    {/* <Link to="index.php" style={{ color: '#2E2E2E', fontWeight: '500' }}>Copyright @ 2023.All Rights Reserved By CloudifyDocs</Link> */}
                    <u>Copyright @ 2023.All Rights Reserved By CloudifyDocs</u>
                </div>
            </div>
        </div>
    )
}

export default Footer
