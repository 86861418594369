import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const LazyCountUp = ({ start, end, style, duration }) => {
    const { ref, inView } = useInView();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);

    return (
        <div ref={ref}>
            {isVisible && (
                <CountUp
                    start={start}
                    end={end}
                    duration={duration} // You can adjust the duration as needed
                    style={{ ...style }}
                />
            )}
        </div>
    );
};

export default LazyCountUp;