import React, { useState, useEffect } from 'react'
import PrimaryBtn from './PrimaryBtn'
import SecondaryBth from './SecondaryBth'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import ScrollToTop from './ScrollTop'

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [buttonClicked, setButtonClicked] = useState(false);

    const handleClick = () => {
        // Perform any other actions if needed
        setButtonClicked(true);
    };

    useEffect(() => {
        const handleScroll = () => {
            // Reset buttonClicked state to false when scrolling occurs
            setButtonClicked(false);
        };

        // Attach the scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Cleanup: remove the event listener on component unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div className={isScrolled ? 'back header-menu' : 'header-menu'} id="masthead">
                <div className="menubar">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 col-sm-12 logo">
                                <div className="menu-logo">
                                    <Link to='/' style={{ textDecoration: 'none' }} onClick={handleClick}>
                                        <strong style={{ color: '#4CCD8D' }}>Cloudify</strong><span>Docs</span>
                                    </Link>
                                    {buttonClicked && <ScrollToTop />}
                                </div>
                            </div>
                            <div className="col-md-10 col-sm-12 sec-center">
                                <div className="page">
                                    <nav className="navbar navbar-expand-lg navbar-light">
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                            <div className='row' style={{ width: '100%' }}>
                                                <div className='col-md-8 nav-pages'>
                                                    <ul className="navbar-nav">
                                                    <li className="nav-item " ><Link to='/' onClick={handleClick} className="nav-link" >Home</Link></li>
                                                        <li className="nav-item " ><HashLink to='/#Threecard' className="nav-link" >Features</HashLink></li>
                                                        <li className="nav-item"  ><HashLink to='/#HowItWorks' className="nav-link">How It Works</HashLink></li>
                                                        <li className="nav-item "><HashLink to='/#simplepricing' className="nav-link">Pricing</HashLink></li>
                                                        <li className="nav-item"><Link className='nav-link' to='/about' style={{ fontSize: '16px', fontWeight: 500, color: '#241C15 !important' }}>About us</Link></li>
                                                        <li className="nav-item"><HashLink to='/#Contactus' className="nav-link">Contact</HashLink></li>
                                                    </ul>
                                                </div>
                                                <div className='col-md-4 but-right p-0'>
                                                    <PrimaryBtn path='https://agent.staging.cloudifydocs.com' title="Login" />
                                                    <SecondaryBth path='https://agent.staging.cloudifydocs.com/registration' titleTwo="Get Free Trial" />
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
