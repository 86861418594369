import React from 'react'
import SecondaryBth from './SecondaryBth'

const HomeBanner = ({ data }) => {
    console.log(data[0]?.title);
    return (
        <div className="main-banner">
            <div className="container">
                <div className="row">
                    <div className="banner-content">
                        <h2 className="roco-font">{data[0]?.title}</h2>
                        <p className='mb-5'>{data[0]?.subtitle}</p>
                        <div className="green-btn">
                            <SecondaryBth path='https://agent.staging.cloudifydocs.com/registration' titleTwo="Get Started" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner
