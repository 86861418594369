import React from 'react'
import LazyCountUp from '../components/LazyCountUp';

const Statistics = ({ data }) => {
    // console.log(data);
    return (
        <div className='statistics-sec'>
            <div className="container">
                <h3 class="roco-font" style={{ color: '#2E2E2E' }}>KEY STATISTICS</h3>
                <div className='stats-container'>
                    <div className='stats-count'>
                        <LazyCountUp
                            start={0}
                            end={data?.agentCount}
                            duration={4}
                            style={{
                                color: "#4ccd8d",
                                fontSize: "50px",
                                fontWeight: 600
                            }}
                        />
                        <h5>USERS</h5>
                    </div>
                    <div className='stats-count mid-count'>
                        <LazyCountUp
                            start={0}
                            end={data?.clientCount}
                            duration={4}
                            style={{
                                color: "#4ccd8d",
                                fontSize: "50px",
                                fontWeight: 600
                            }}
                        />
                        <h5>CLIENTS</h5>
                    </div>
                    <div className='stats-count'>
                        <LazyCountUp
                            start={0}
                            end={data?.taskCount}
                            duration={4}
                            style={{
                                color: "#4ccd8d",
                                fontSize: "50px",
                                fontWeight: 600
                            }}
                        />
                        <h5>TASKS</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistics