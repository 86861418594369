import React from 'react'
import mailbox from '../assets/image/mail.png'
import SecondaryBth from '../components/SecondaryBth'
import { Link } from 'react-router-dom'

const Mail = () => {
  return (
    <div className="registration">
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='left-sec'>
                        <div className='logo'>
                            <h2><Link to="/"><strong style={{color:'#4CCD8D'}}>CAD</strong> <span style={{color:'#548CFF'}}>IT Solutions</span></Link></h2> 
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="rigester-box mail-box">
                        <div className="get-start-login">
                            <img src={mailbox}/>
                            <h2>check your mail</h2>
                            <p>Reset password link existing to your email address </p>
                            <div className='sec-btns d-flex justify-content-center mt-5'>
                                <SecondaryBth path='/login' titleTwo="Back To Login"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Mail
