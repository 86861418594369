import React, { useState, useEffect } from 'react';
import HomeBanner from '../components/HomeBanner';
import HomeCard from '../components/HomeCard';
import Threestep from '../components/Threestep';
import SimplePricing from '../components/SimplePricing';
import About from '../components/About';
import NonProfit from '../components/NonProfit';
import Testimonial from '../components/Testimonial';
import ContactUs from '../components/ContactUs';
import { dashboardData, getOrgData } from '../service/service';
import Statistics from './Statistics';

const Home = () => {

  const [info, setInfo] = useState()
  const [dashdata, setDashData] = useState()

  console.log(dashdata);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getOrgData(setInfo); // Call the function and pass the setter function
      } catch (error) {
        console.error('Error fetching home card data:', error);
      }
    };
    const getDashData = async () => {
      try {
        await dashboardData(setDashData); // Call the function and pass the setter function
      } catch (error) {
        console.error('Error fetching home card data:', error);
      }
    };
    fetchData(); // Call the function when the component mounts
    getDashData()
  }, []);

  return (
    <>
      {info && <HomeBanner data={info} />}
      <HomeCard />
      <Threestep />
      <SimplePricing />
      {info && <About data={info} />}
      {dashdata && <Statistics data={dashdata} />}
      <NonProfit data={Array.isArray(info) ? info[0] : info} />

      {/* <Testimonial /> */}
      {info && <ContactUs data={info} />}
    </>
  )
}

export default Home;
