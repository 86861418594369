import React, { useEffect } from 'react'
import ScrollToTop from './ScrollTop'

const InnerBanner = ({title}) => {

  return (
    <>
      {/* <ScrollToTop /> */}
      <div  id="banner-top" className="inner-banner">
        <div className="conatiner">
          <h2>{title}</h2>
        </div>
      </div>
    </>
  )
}

export default InnerBanner
