import React from 'react'
import { Link } from 'react-router-dom'

const SecondaryBth = ({ titleTwo, path }) => {
  return (
    <Link target='_' className="trail" to={path} style={{ textDecoration: 'none' }}>{titleTwo}</Link>
  )
}

export default SecondaryBth
