import React, { useEffect, useState } from 'react'
import InnerBanner from '../components/InnerBanner'
import { getFaqData } from '../service/service';

const Faq = () => {

    const [data, setData] = useState()

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getFaqData(setData); // Call the function and pass the setter function
            } catch (error) {
                console.error('Error fetching home card data:', error);
            }
        };
        fetchData(); // Call the function when the component mounts
    }, []);

    return (
        <>
            <InnerBanner title="FAQ'S" />
            <div className="faq-acc">
                <div className="container">
                    <div className="inner-head">
                        <h2>FAQ’s</h2>
                    </div>
                    <div id="accordion" className="accordion">
                        <div className="card mb-0">
                            {data && data?.map((ele, index) => (
                                <div className="faqs" key={index}>
                                    <div className={`card-header ${index == 1 ? 'collapsed' : ''}`} data-toggle="collapse" href={`#collapseOne${index}`} aria-expanded="true">
                                        <a className="card-title text-decoration-none">
                                            {ele?.question}
                                        </a>
                                    </div>
                                    <div id={`collapseOne${index}`} className={`card-body collapse ${index == 0 ? 'show' : ''}`} data-parent="#accordion">
                                        <p>{ele?.answer}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Faq
