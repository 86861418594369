import React from 'react'
import SecondaryBth from './SecondaryBth'
import { HashLink } from 'react-router-hash-link'

const NonProfit = ({ data }) => {
   // console.log(data?.Off_on_Non_Profits !== null);
  return (
    <div className="container">
      <div className="nonprofit" style={{ backgroundColor: '#F6FAFF' }}>
        <div className="sec-head">
          <h3 className="roco-font mb-3">{data?.Off_on_Non_Profits !== null ? data?.Off_on_Non_Profits : 0}% Off on Non-Profits Organizations</h3>
          <p>Focus on your cause, not your workload. CLOUDIFY Docs offers discounted plans and tools to streamline your operations.</p>
        </div>
        <div className="green-btn">
          <HashLink to='/#Contactus' style={{ textDecoration: 'none' }}>
            <SecondaryBth titleTwo="Get Your Plan Now" />
          </HashLink>
        </div>
      </div>
    </div>
  )
}

export default NonProfit
