import axios from "axios";

export const API_URL =
    process.env.REACT_APP_BACKEND_BASEURL +
    "/api/" +
    process.env.REACT_APP_NODE_ENV +
    "/v1";

export const contactForm = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/contactUs`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log(response);
        const responseData = response.status;
        console.log(responseData);
        return responseData; // Assuming your server returns user data upon successful registration
    } catch (error) {
        throw error.message; // Handle network errors
    }
};

export const getTermsData = async (setTermsData) => {
    try {
        const response = await axios.get(`${API_URL}/organization/termsAndCondition`);
        console.log(response?.data?.data)// Assuming your server returns a token upon successful login
        return setTermsData(response?.data?.data?.reverse())
    } catch (error) {
        throw error.response.data.error; // Handle login errors
    }
};

export const getGdprData = async (setData) => {
    try {
        const response = await axios.get(`${API_URL}/organization/GDPR`);
        console.log(response?.data?.data)// Assuming your server returns a token upon successful login
        return setData(response?.data?.data)
    } catch (error) {
        throw error.response.data.error; // Handle login errors
    }
};

export const getPrivacyData = async (setData) => {
    try {
        const response = await axios.get(`${API_URL}/organization/privacyPolice`);
        console.log(response?.data?.data)// Assuming your server returns a token upon successful login
        return setData(response?.data?.data)
    } catch (error) {
        throw error.response.data.error; // Handle login errors
    }
};

export const getFaqData = async (setData) => {
    try {
        const response = await axios.get(`${API_URL}/faq/admin`);
        console.log(response?.data?.data)// Assuming your server returns a token upon successful login
        return setData(response?.data?.data?.rows)
    } catch (error) {
        throw error.response.data.error; // Handle login errors
    }
};

export const getOrgData = async (setInfo) => {
    try {
        const response = await axios.get(`${API_URL}/organization/`);
        // console.log(response?.data?.data[0])// Assuming your server returns a token upon successful login
        return setInfo(response?.data?.data)
    } catch (error) {
        throw error.response.data.error; // Handle login errors
    }
};

// export const getSubscription = async (setData) => {
//     try {
//         const response = await axios.get(`${API_URL}/billing/admin/active`);
//         console.log(response?.data)// Assuming your server returns a token upon successful login
//         return setData(response?.data)
//     } catch (error) {
//         throw error.response.data.error; // Handle login errors
//     }
// };

export const getPlans = async () => {
    try {
        return await axios.get(`${API_URL}/billing/public/active`)
    } catch (error) {
        return error.response
    }
}
// TODO endpoint needs to be changed
export const dashboardData = async (setDashData) => {
    try {
        const response = await axios.get(`${API_URL}/dashboard/`)
        return setDashData(response?.data?.data?.data)
    } catch (error) {
        return error.response
    }
}