import React, { useEffect, useState } from 'react'
import NonProfit from '../components/NonProfit'
import PricingTable from '../components/PricingTable'
import ScrollToTop from '../components/ScrollTop'
import { getOrgData } from '../service/service'

const Pricing = () => {
  const [info, setInfo] = useState()
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getOrgData(setInfo); // Call the function and pass the setter function
      } catch (error) {
        console.error('Error fetching home card data:', error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <ScrollToTop />
      <div id="pricing-container" className="main-banner pricing-banner">
        <div className="container">
          <div className="row">
            <div className="banner-content">
              <h2 className="roco-font">"Adaptable packages inclusive of training and seamless data transition."</h2>
              <p>Start your 14 days trial now. Cancel anytime.</p>
            </div>
          </div>
        </div>
      </div>
      {/* <SwitchButton /> */}
      <PricingTable />
      <NonProfit data={Array.isArray(info) && info[0]} />
      {/* <DashFaq /> */}
    </>
  )
}

export default Pricing;
