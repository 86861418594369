import React from "react";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactForm } from "../service/service";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { FormControl, TextField } from "@mui/material";

const ContactUs = ({ data }) => {

  const [open, setOpen] = React.useState(false);

  const schema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(4, 'Name must be at least 4 characters')
      .matches(/^[A-Za-z\s]+$/, 'Name must only contain letters and spaces'),
    email: Yup.string()
      .required("Email is required")
      .matches(
        // Regular expression pattern including top-level domains (TLDs)
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email address is required"
      )
      .email("Email address must be a valid email"),
    contactType: Yup.string()
      .required("Message is required")
      .min(4, 'Message must be at least 4 characters'),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = async (data) => {
    // Handle form submission logic here
    console.log(data);
    try {
      const response = await contactForm(data);
      console.log('Contact form submitted successfully:', response);

      if (response === 201) {
        setOpen(true)
      }
    } catch (error) {
      console.error('Error submitting contact form:', error);
      // Handle error
    }
    reset()
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  return (
    <div id="Contactus" className="get-in-touch">
      <div className="container">
        <div className="row">
          <div className="col-md-7 cont-desc">
            <div className="sec-head">
              <h3 className="roco-font mb-3">Get In Touch</h3>
              <p>{data[0]?.description}</p>
            </div>
            <div className="contect">
              <ul className="d-flex flex-wrap">
                <li className="align-items-start col-6">
                  <i className="fa fa-map-marker" aria-hidden="true"></i> : {data[0]?.address}
                </li>
                <li className="align-items-start col-6">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i> :&nbsp; <a className="text-decoration-none text-dark" href={`mailto:${data[0]?.email}`}>{data[0]?.email}</a>
                </li>
                <li className="align-items-start col-6">
                  <i className="fa fa-whatsapp" aria-hidden="true"></i> : {data[0]?.mobile_number}
                </li>
                <li className="align-items-start col-6">
                  <i className="fa fa-mobile" aria-hidden="true"></i> :&nbsp; <a className="text-decoration-none text-dark" href={`tel:${data[0]?.phone_number}`}>{data[0]?.phone_number}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-5 form-sec">
            <div className="contact-form" style={{ position: 'relative', boxShadow: '0px 0px 10px #00000042', borderRadius: '7px', backgroundColor: '#fff' }}>
              <h2>CONTACT US</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-4">
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      error={errors.name ? true : false}
                      label="Your Name"
                      helperText={errors.name?.message}
                      {...register("name")}
                    />
                  </FormControl>
                </div>
                <div className="form-group mb-4">
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      error={errors.email ? true : false}
                      label="Email Address"
                      helperText={errors.email?.message}
                      {...register("email")}
                    />
                  </FormControl>
                </div>
                <div className="form-group mb-4">
                  <FormControl fullWidth>
                    <TextField
                      multiline
                      rows={4}
                      error={errors.contactType ? true : false}
                      label="Message"
                      helperText={errors.contactType?.message}
                      {...register("contactType")}
                    />
                  </FormControl>
                </div>
                <button type="submit" className="submit-btn d-flex justify-content-center align-items-center" style={{ backgroundColor: '#4CCD8D', width: '100%', color: '#fff', borderRadius: '3px', border: 'none', height: '48px' }}>
                  SEND
                </button>
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                  <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                  >
                    Thank you for contacting us. We appreciated you message inquiry. A representative from Cloudify Docs will reach out to you within 2 business days
                  </Alert>
                </Snackbar>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
