import React, { useEffect ,useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StepOne from '../components/Steps/StepOne';
import StepTwo from '../components/Steps/StepTwo';
import StepThree from '../components/Steps/StepThree';
import SeccessfulModal from '../components/Steps/SeccessfulModal';
import { Link } from 'react-router-dom';
import PositionedSnackbar from '../components/Steps/DummyFile';


const steps = ['1','2','3'];


export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState('');
  const [step2, setStep2] = useState('')
  const [step3, setStep3] = useState('')
  const [showSnackbar, setShowSnackbar] = useState(false);

//   console.log(activeStep);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };



  const handleNext = () => {
    if(value !== '' && activeStep === 0){
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
    else if(step2 !== '' && activeStep === 1){
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
    else{
      setShowSnackbar(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleOpen = () => {
    if(step3 !== '' && activeStep === 2){
      setIsOpen(true)
    } 
    else{
      setShowSnackbar(true);
    } 
  }

  
  

  return (
    <div className="registration">
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='left-sec'>
              <div className='logo'>
                  <h2><Link to="/"><strong style={{color:'#4CCD8D'}}>CAD</strong> <span style={{color:'#548CFF'}}>IT Solutions</span></Link></h2>
              </div>
              <div className="get-start">
                <h2>Get Started</h2>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
             <div className="rigester-box stapper-box">
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} style={{marginBottom:'50px'}}>
                        {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                            <Typography variant="caption"></Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                        })}
                    </Stepper>
                {activeStep === steps.length ? (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                        </Box>
                    </>
                ) : (
                    <React.Fragment>

                    {activeStep === 0 ? (
                        <StepOne value={value} setValue={setValue}/>
                    ) : activeStep === 1 ? (
                        <StepTwo step2={step2} setStep2={setStep2}/>
                    ) :  (
                        <StepThree step3={step3} setStep3={setStep3}/>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 10 }}>
                        <Button className='back-btn'
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                        Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button onClick={ activeStep < 2 ? handleNext : handleOpen } className='next-btn'>
                            {activeStep < 2  ? 'Next' : 'Next'}
                            
                        </Button>
                            <SeccessfulModal isOpen={isOpen} setIsOpen={setIsOpen} />
                    </Box>
                    <PositionedSnackbar  openError={showSnackbar} setOpenError={setShowSnackbar} />
                    </React.Fragment>
                    
                )}
                </Box>
             </div>
          </div>
        </div>
      </div>
      <div>
    </div>
    </div>
  );
}
