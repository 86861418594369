import React, { useState } from 'react'

const StepOne = ({value, setValue}) => {
  const [activeButton, setActiveButton] = useState(null);
  

  const handleButtonClick = (index, val) => {
    setActiveButton(index);
    setValue(val)
  };

  console.log(activeButton)


  return (
    <div style={{position:'relative'}} >
      <div className="next-step">
            <p>Step 1 Of 3</p> 
        </div>
        <h2>Hi, Jaliya</h2>
        <h3>How Did You Get To Know About Us?</h3>
        <div className="select-btn">
          <button
            className={activeButton === 0 ? 'active' : ''}
            onClick={() => handleButtonClick(0, 'Email Marketing')}
          >
            Email Marketing
          </button>
          <button
            className={activeButton === 1 ? 'active' : ''}
            onClick={() => handleButtonClick(1,'Social Media(Platform)')}
          >
            Social Media(Platform)
          </button>
          <button
            className={activeButton === 2 ? 'active' : ''}
            onClick={() => handleButtonClick(2,'Referral')}
          >
            Referral
          </button>
          <button
            className={activeButton === 3 ? 'active' : ''}
            onClick={() => handleButtonClick(3,'Advertisement')}
          >
            Advertisement
          </button>
          <button
            className={activeButton === 4 ? 'active' : ''}
            onClick={() => handleButtonClick(4,'Website')}
          >
            Website
          </button>
          <button
            className={activeButton === 5 ? 'active' : ''}
            onClick={() => handleButtonClick(5,'Other')}
          >
            Other
          </button>
        </div>
    </div>
  )
}

export default StepOne
