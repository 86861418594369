import React, { useState }  from 'react'

const StepThree = ({value,setStep3}) => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (index,val) => {
    setActiveButton(index);
    setStep3(val)
  };

  return (
    <div style={{position:'relative'}}>
        <div className="next-step">
            <p>Step 3 Of 3</p> 
        </div>
        <h3>How Many Employees Do You Have?</h3>
        <div className="select-btn stpe-three">
            <button className={activeButton === 0 ? 'active' : ''}
              onClick={() => handleButtonClick(0,'1-4')}>
              1-4
            </button>
            <button className={activeButton === 1 ? 'active' : ''}
              onClick={() => handleButtonClick(1,'5-9')}>
              5-9
            </button>
            <button className={activeButton === 2 ? 'active' : ''}
              onClick={() => handleButtonClick(2,'10-49')}>
              10-49
            </button>
            <button className={activeButton === 3 ? 'active' : ''}
             onClick={() => handleButtonClick(3,'50-99')}>
             50-99
            </button>
            <button className={activeButton === 4 ? 'active' : ''}
             onClick={() => handleButtonClick(4,'Larger')}>
             Larger
            </button>
        </div>
    </div>
  )
}

export default StepThree
