import React from 'react'
import BrandGoole from '../assets/image/brand-google.png'
import OutLook from '../assets/image/outlook.png'
import { Link } from 'react-router-dom'

const RegistrationTwo = () => {
  return (
    <div className="registration">        
        <div className="container">
            <div className='row'>
                <div className='col-md-6'>
                    <div className='left-sec'>
                        <div className='logo'>
                            <h2><Link to="/"><strong style={{color:'#4CCD8D'}}>CAD</strong> <span style={{color:'#548CFF'}}>IT Solutions</span></Link></h2> 
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="rigester-box">
                        <div className="get-start">
                            <h2 style={{marginBottom:'20px'}}>Please verify your email</h2>
                            <p style={{marginBottom:'50px'}}>Once You Verify Your Email Address , You And Your Team Can Get Started</p>
                        </div>
                        <div className="open-box row">
                            <div className="col-md-6">
                                <Link to="/signin">
                                    <img src={BrandGoole} style={{marginRight:'10px'}}/>
                                    Open Gmail
                                </Link>
                            </div>
                            <div className="col-md-6">
                                <Link to="/signin">
                                    <img src={OutLook} style={{marginRight:'10px'}} />
                                    Open Outlook
                                </Link>
                            </div>
                        </div>
                        <div className="check text-center" style={{justifyContent:'center',marginTop:'48px'}}>
                            <p>Didn’t Received An Email ? <Link to="">Resend Email</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default RegistrationTwo
