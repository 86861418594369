import React from 'react'
import { Link } from 'react-router-dom'



const PrimaryBtn = ({ title, path }) => {
  return (
    <Link target='_' className="login" to={path} style={{ textDecoration: 'none' }}>{title}</Link>
  )
}

export default PrimaryBtn
