import React from 'react'
import SecondaryBth from '../components/SecondaryBth'
import { Link } from 'react-router-dom'

const ForgetPassword = () => {
  return (
    <div className="registration">
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='left-sec'>
                        <div className='logo'>
                            <h2><Link to="/"><strong style={{color:'#4CCD8D'}}>CAD</strong> <span style={{color:'#548CFF'}}>IT Solutions</span></Link></h2>          
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="rigester-box login">
                        <div className="get-start-login">
                            <h2>Forgot password</h2>
                        </div>
                        <form>
                            <div className="form-group mt-4">
                                <label>Email Address</label><br/>
                                <input type="text" placeholder="reethik@gmail.com" />
                            </div>
                            <div className='sec-btns d-flex justify-content-center mt-5 mb-4'>
                                <SecondaryBth path='/mail' titleTwo="Reset Password"/>
                            </div>
                            <div className="sending-mess">
                                <p>We Send a password reset link to</p>
                                <p>priyanka@gmail.com</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ForgetPassword
