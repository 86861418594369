import React from 'react'
import SecondaryBth from '../components/SecondaryBth'
import { Link } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox';

const SignIn = () => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <div className="registration">
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='left-sec'>
                        <div className='logo'>
                            <h2><Link to="/"><strong style={{color:'#4CCD8D'}}>CAD</strong> <span style={{color:'#548CFF'}}>IT Solutions</span></Link></h2> 
                        </div>
                        <div className="get-start-login mb-5">
                            <h2 style={{marginBottom:'5px',textAlign:'center'}}>Welcome to our Cad It Solutions</h2>
                            <p style={{color:'#484848',textAlign:'center'}}>You’re signing up as name@gmail.com</p>
                        </div>
                    </div>
                </div>  
                <div className='col-md-6'>
                    <div className="rigester-box login">
                        <form>
                            <div className="form-group mb-3">
                                <label>First Name</label><br/>
                                <input type="text" placeholder='' />
                            </div>
                            <div className="form-group mb-3">
                                <label>Last Name</label><br/>
                                <input type="text" placeholder="" />
                            </div>
                            <div className="form-group mb-3">
                                <label>Password</label><br/>
                                <input type="text" placeholder="" />
                            </div>
                            <div className="check mt-4">
                                <Checkbox
                                    {...label}
                                    sx={{
                                        color:'#4CCD8D',
                                        '&.Mui-checked': {
                                        color: '#4CCD8D',
                                        },
                                    }}
                                />
                                <p>Get Feature Updates And Tips Via Email(Recommended).</p>
                            </div>
                            <div className='sec-btns d-flex justify-content-center mt-5'>
                                <SecondaryBth path='/stapper' titleTwo="Continue"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SignIn
