import React, { useState } from 'react'

const Card = ({ imgUrl, title, disc, discShow, titleTwo }) => {

  const [showMore, setShowMore] = useState(false);

  const handleShowMoreClick = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="col-md-4">
      <div className="card-box">
        <div className="card">
          <h2 
          className='bg-white ps-3 pe-3 p-1 rounded-2'
          style={{
            // textShadow:"2px 2px 4px rgba(0, 0, 0, 0.5)",
            color:"#000",

          }}>{title}<br />{titleTwo}</h2>
          <img src={imgUrl} alt='cardImage' />
        </div>
        <div className="card-text">
          <p style={
            {
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxHeight: showMore ? '18em' : '3em',
              transition: "all ease 1s"
            }
          }>
            {disc} {discShow}
          </p>
          <span
            style={{
              color: "#1A1D84",
              cursor: "pointer",
            }}
            onClick={handleShowMoreClick}>{showMore ? 'Read Less' : 'Read More'}</span>
        </div>
      </div>
    </div>
  )
}

export default Card
